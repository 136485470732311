import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import i18next from 'i18next';

async function fetchTime(block) {
  try {
    const response = await fetch(
      `${process.env.WALLET_URL}v4/polls-service/polls/time/${block}`
    );
    const data = await response.json();
    const timestamp2 = data.time * 1000;
    const time = new Date(timestamp2);
    time.setHours(0, 0, 0, 0);
    const day = ('0' + time.getDate()).slice(-2);
    const month = ('0' + (time.getMonth() + 1)).slice(-2);
    const year = time.getFullYear();
    return `${day}.${month}.${year}`;
  } catch (error) {
    console.error('Произошла ошибка при получении данных:', error);
    return '';
  }
}

async function fetchActivePolls() {
  try {
    const responseData = await fetch(
      `${process.env.WALLET_URL}v4/polls-service/polls?status=active&info_level=medium&language=${i18next.language}`
    );
    const data = await responseData.json();
    return data?.polls || [];
  } catch (error) {
    console.error('Произошла ошибка при получении данных:', error);
    return [];
  }
}
const splideList = document.querySelector('.splide__list');
const oneSlide = document.querySelector('.one_slide');

function renderPolls(pollsArray) {
  const startBlockText = i18next.t('Start voting from block:');
  const endBlockText = i18next.t('End of voting in the block:');
  const statusText = i18next.t('Status');
  const minAmountText = i18next.t('Minimum number of OVG to vote:');
  const activeText = i18next.t('Active');
  const voteText = i18next.t('Vote');
  const circleText1 = i18next.t('votes from the');
  const circleText2 = i18next.t('required');
  const circleText3 = i18next.t('votes');

  const votingCounter = document.getElementById('voting_counter');
  const votingLi = document.getElementById('voting_li');
  const faqLi = document.getElementById('faq_li');

  const arrowPrev = document.querySelector('.arrow_prev');
  const arrowNext = document.querySelector('.arrow_next');

  if (pollsArray.length > 0) {
    votingLi.style.display = 'block';
    faqLi.style.display = 'none';
  } else {
    votingLi.style.display = 'none';
    faqLi.style.display = 'block';
  }
  votingCounter.innerHTML = pollsArray.length;

  pollsArray.forEach(async activePoll => {
    const startTime = await fetchTime(activePoll.start_block);
    const endTime = await fetchTime(activePoll.end_block);
    const cards = document.querySelectorAll('.voting_card_wrapper');

    const pollItem = document.createElement('li');
    pollItem.classList.add('splide__slide');

    const stepDeg = 360 / activePoll.min_users_voted;
    const userVoted = Math.min(
      activePoll.users_voted,
      activePoll.min_users_voted
    );

    const pollsLink = `${process.env.POLLS_URL}?lang=${i18next.language}`;
    const votingCard = `
      <div class="voting_card_wrapper" key='${activePoll.start_block}'>
  <p class="voting_card_title">${activePoll.title}</p>
  <p class="voting_card_description">${activePoll.description}</p>
  <div class="content_wrapper">
    <div class="info_wrapper">
      <div class="info_punkt">
        <p class="start_block" >${startBlockText}</p>
        <span class="block_number">${activePoll.start_block}</span>
        <p> (${startTime})</p>
      </div>
      <div class="info_punkt">
        <p class="end_block" >${endBlockText}</p>
        <span class="block_number">${activePoll.end_block}</span>
        <p>(${endTime})</p>
      </div>
      <div class="info_punkt">
        <p class="" >${minAmountText}</p>
        <span class="min_amount_ovg_number">${activePoll.min_amount}</span>
      </div>
      <div class="info_punkt">
        <p class="start_block" >${statusText}</p>
        <span class="status_green" >${activeText}</span>
      </div>
    </div>
    <div class="voting_card_circle_wrapper">
      <div class="circular_progress" style="background: conic-gradient(#16BDF9 ${
        userVoted * stepDeg
      }deg, #222A33 0deg)"></div>
      <div class="circle_info">
        <p class="users_voted ">${activePoll.users_voted}</p>
        <div class='min_users_voted_wrapper ${
          activePoll.users_voted >= activePoll.min_users_voted ? 'd_none' : ''
        }'><p class="min_users_voted">${circleText1}</p>
           <p class="min_users_voted_number">${
             activePoll.min_users_voted
           }</p></div>
        <p class="min_users_voted ${
          activePoll.users_voted >= activePoll.min_users_voted ? 'd_none' : ''
        }">${circleText2}</p>
         <p class="min_users_voted ${
           activePoll.users_voted >= activePoll.min_users_voted ? '' : 'd_none'
         }">${circleText3}</p>
      </div>

    </div>
  </div>
  <div class="button_wrapper">
    <a class="standart_button polls_button"  target="_blank" data-service="Polls" href=${pollsLink}>${voteText}</a>
  </div>
</div>

    `;

    pollItem.insertAdjacentHTML('afterbegin', votingCard);
    if (pollsArray.length === 1) {
      while (oneSlide.firstChild) {
        oneSlide.removeChild(oneSlide.firstChild);
      }
      arrowPrev.style.display = 'none';
      arrowNext.style.display = 'none';
      oneSlide.appendChild(pollItem);
    } else {
      splideList.appendChild(pollItem);
      if (cards.length >= pollsArray.length - 1) {
        new Splide('#carousel', {
          perPage: 1,
          type: 'loop',
          classes: {
            arrows: 'splide__arrows arrows',
            arrow: 'splide__arrow arrow',
            prev: 'splide__arrow--prev arrow_prev',
            next: 'splide__arrow--next arrow_next',
          },
        }).mount();
      }
    }
  });
}

async function fetchData() {
  try {
    const noVoting = document.querySelector('.no_voting_wrapper');
    const voting = document.querySelector('.voting_wrapper');
    const votingCounter = document.getElementById('voting_counter');

    voting.style.display = 'none';

    const pollsArray = await fetchActivePolls();
    i18next.language === 'en'
      ? (votingCounter.style.right = '8px')
      : (votingCounter.style.right = '-8px');
    if (pollsArray.length >= 1) {
      noVoting.style.display = 'none';
      voting.style.display = 'flex';

      renderPolls(pollsArray);

      return pollsArray;
    } else {
      voting.style.display = 'none';
      noVoting.style.display = 'flex';
    }
  } catch (error) {
    console.error('Произошла ошибка:', error);
  }
}

document.addEventListener('DOMContentLoaded', async () => {
  await fetchData();

  i18next.on('languageChanged', async () => {
    while (splideList.firstChild) {
      splideList.removeChild(splideList.firstChild);
    }

    new Splide('#carousel', {
      destroy: true,
    }).mount();

    await fetchData();
  });
});
